import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';
import PageContainer from 'src/components/PageContainer';
import { Form, FormControls } from 'src/components/StyledCommon';
import BackButton from 'src/components/BackButton';

import store from 'src/store';
import api, { ChronosAuthApi ,ApiErrorDescriptions } from 'src/services/api';
import { useTranslation } from 'src/i18n/useTranslation';

import ReCAPTCHA from "react-google-recaptcha";
import { Button, Input, InputContainer, TInputMode } from 'src/libs';

export default ReactMemo(function ForgotPassword() {
  const navigate = useNavigate();
  const [channel, setChannel] = React.useState<string>('');
  const [channelFieldMode, setChannelFieldMode] = React.useState<TInputMode>('normal');
  const [channelMessage, setChannelMessage] = React.useState<string>('');

  const captchaRef = React.useRef<ReCAPTCHA>(null)

  React.useEffect(() => {
    setChannelFieldMode('normal');
    setChannelMessage('');
  }, [channel]);

  const onSubmit = async (evt: any) => {
    evt && evt.preventDefault();
    store.setLoading(true);
    try {
      let channelResult = channel.replace(/[\\(\\)\s]/g, '');
      // российские номера приводим к +7......... форме
      if (/^8/.test(channelResult) && channelResult.length === 11) {
        channelResult = channelResult.replace(/^8/, '+7')?.trim()
      }
      const token = await captchaRef.current?.executeAsync()
      if (token) {
        const result = await ChronosAuthApi.restore({ channel: channelResult, token });
        store.auth.setChannel(channelResult);
        navigate('/confirmation-restore');
      } else {
        console.error('Captcha error')
      }
    } catch (error: any) {
      setChannelFieldMode('error');
      setChannelMessage(t(error.message));
    } finally {
      store.setLoading(false);
    }
  }
  const { t } = useTranslation();

  return (
    <PageContainer
      backButton={<BackButton to='/signin' caption={t("chronos.auth.forgotPassword.backEntrance")}/>}
    >
      <Form className="form signin" onSubmit={onSubmit}>
        <div className="form-header">
          <div className="form-title">{t("chronos.auth.forgotPassword.forgotPassword")}</div>
          <div className="form-subtitle">{t("chronos.auth.forgotPassword.sendEmail")}</div>
        </div>
        <div className="form-content">
          <InputContainer label={t("chronos.auth.forgotPassword.emailOrPhone")} message={t(channelMessage)}>
            <Input
              name="channel"
              size='big'
              value={channel}
              mode={channelFieldMode}
              clearable
              onChange={(val: string) => setChannel(val)}
            />
          </InputContainer>
          <FormControls className='form-buttons'>
            <Button name="submit-button" type="submit" size='big' disabled={!channel}>{t("chronos.auth.forgotPassword.restore")}</Button>
          </FormControls>
        </div>
      </Form>

      <ReCAPTCHA
        sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
        size="invisible"
        ref={captchaRef}
      />
    </PageContainer>
  )
});
