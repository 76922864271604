import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ReactMemo } from 'src/hooks';
import PageContainer from 'src/components/PageContainer';
import { ButtonWithIcon, Form, SocialButtons, FormControls } from 'src/components/StyledCommon';
import { BackButtonContainer } from 'src/components/BackButton';

import store from 'src/store';
import api, { authWithGoogle, ChronosAuthApi } from 'src/services/api';

import { ReactComponent as LogoIcon } from 'src/assets/icons/chronos-logo.svg';
import { ReactComponent as GoogleIcon } from 'src/assets/icons/social/google.svg';
import { ReactComponent as CallIcon } from 'src/assets/icons/system/call.svg';
import styled from 'styled-components';
import { useTranslation } from 'src/i18n/useTranslation';
import {gtmSend} from "../../utils";
import { InputContainer, Input, Button } from 'src/libs';

const EMPTY_CHANNEL = 'WRONG CHANNEL, channel should not be empty'
const EMPTY_PASSWORD = 'WRONG CHANNEL, password should not be empty'
const EMPTY_PASSWORD_2 = 'password should not be empty'
const EMPTY_CHANNEL_PASSWORD = 'WRONG CHANNEL, channel should not be empty, password should not be empty'

export default ReactMemo(function SignIn() {
  const navigate = useNavigate();

  const [state, setState] = React.useState<{
    channel: string;
    password: string;
    channelMode: any;
    channelMessage: string;
    passwordMode: any;
    passwordMessage: string;
  }>({
    channel: '',
    password: '',
    channelMode: 'normal',
    channelMessage: '',
    passwordMode: 'normal',
    passwordMessage: ''
  });

  const isFormValid = React.useMemo(() => {
    return Boolean(state.channel && state.password)
  }, [state.channel, state.password]);

  const changeStateField = (field: string, value: any) => {
    setState(state => ({
      ...state,
      channelMode: 'normal',
      channelMessage: '',
      passwordMode: 'normal',
      passwordMessage: '',
      [field]: value
    }));
  }

  const submitForm = async (evt: any) => {
    evt && evt.preventDefault();
    store.setLoading(true);
    try {
      const result = await ChronosAuthApi.login({ channel: state.channel.trim(), password: state.password.trim() });
      debugger
      store.auth.setJid(result.jid);
      store.auth.tryRedirectToReferredApp({
        url: store.auth.referrer,
        success: () => {
          // const partner = this.$store.getters.partner
          // if (partner && this.$metrika) {
          //   this.$metrika.userParams({
          //     partner: Boolean(partner),
          //     ['partner_id']: partner
          //   })
          //   this.$metrika.params({
          //     ['from_partner']: partner
          //   })
          // }
        },
        fail: error => {
          console.log('* from signin redirect err -', error)
        }
      })

      const userId = store.auth.User?.userId
      
      gtmSend({'event': 'logIn-successed', 'user_id': userId ? userId : ''})

    } catch (error: any) {
      let type = 'error';
      let channelErrorMessage = "";
      let passwordErrorMessage = "";
      channelErrorMessage = error.message;
      passwordErrorMessage = error.message;
      setState(state => ({
        ...state,
        channelMode: channelErrorMessage ? type : 'normal',
        channelMessage: channelErrorMessage,
        passwordMode: passwordErrorMessage ? type : 'normal',
        passwordMessage: passwordErrorMessage
      }));
    } finally {
      store.setLoading(false);
    }
  }
  const { t } = useTranslation();

  return (
    <PageContainer
      actionButton={
        <BackButtonStyled>
          <span>{t("chronos.auth.resetPassword.notAccount")}</span>&nbsp;
          <Link to="/signup">{t("chronos.auth.resetPassword.letCreate")}</Link>
        </BackButtonStyled>
      }
    >
      <Form className="form signin" onSubmit={submitForm}>
        <div className="form-header">
          <div className="chronos-logo"><LogoIcon /></div>
          <div className="form-title">{t("chronos.auth.resetPassword.inEntranceChronos")}</div>
          <div className="form-subtitle">{t("chronos.auth.resetPassword.profileUsedSite")}</div>
        </div>
        <div className="form-content" onSubmit={submitForm}>

          <InputContainer label={t("chronos.auth.signIn.email")} message={t(state.channelMessage)}>
            <Input
              name="login"
              size='big'
              value={state.channel}
              mode={state.channelMode} 
              clearable
              onChange={(val: string) => changeStateField('channel', val)}
            />
          </InputContainer>

          <InputContainer label={t("chronos.auth.login.password")} message={t(state.passwordMessage)}>
            <Input
              size='big'
              name="password"
              value={state.password}
              type="password"
              mode={state.passwordMode} 
              onChange={(val: string) => changeStateField('password', val)}
            />
          </InputContainer>

          <div className="separator"><span>{t("chronos.auth.signUp.or")}</span></div>

          <SocialButtons>
            <Button name="google-button" color="transparent" bordered={true} style={{ padding: '0.563rem 0.5rem', margin: '1rem 0 1.5rem 0' }} onClick={authWithGoogle}>
              <ButtonWithIcon><GoogleIcon /><span>{t("chronos.auth.login.signWithGoogle")}</span></ButtonWithIcon>
            </Button>
  
            <Button name="phone-button" onClick={() => navigate('/signin-phone')} color="transparent" bordered={true} style={{ padding: '0.563rem 0.5rem' }} >
              <ButtonWithIcon><CallIcon /><span>{t("chronos.auth.resetPassword.signWithPhone")}</span></ButtonWithIcon>
            </Button>
          </SocialButtons>

          <FormControls className='form-buttons'>
            <Button name="submit-button" type="submit" size='big' disabled={!isFormValid}>{t("chronos.app.autorisation.signIn")}</Button>
            <Button name="forgot-button" type="button" size='big' color="transparent" onClick={() => navigate('/password-forgot') }>{t("chronos.auth.forgotPassword.forgotPassword")}</Button>
          </FormControls>

        </div>
      </Form>
    </PageContainer>
  );
});

const BackButtonStyled = styled(BackButtonContainer)`
  a {
    color: var(--accent-blue);
    font-weight: 400;
    margin-left: 0;
  }
`;

