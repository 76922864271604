import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactMemo } from 'src/hooks';
import PageContainer from 'src/components/PageContainer';
import { Form, FormControls } from 'src/components/StyledCommon';
import CountDown from 'src/components/CountDown';
import BackButton from 'src/components/BackButton';
import { TabsCommutator } from 'src/utils';

import store from 'src/store';
import { ChronosAuthApi } from 'src/services/api';
import { useTranslation } from 'src/i18n/useTranslation';
import { Button, Input, InputContainer, TInputMode, show } from 'src/libs';
import ReCAPTCHA from "react-google-recaptcha";

const tabsCommutationKey = 'confirm-signup-email';

export default ReactMemo(function ConfirmationEmail() {
  const params = useLocation();
  const [confirmCode, setConfirmCode] = React.useState<string>('');
  const [confirmMode, setConfirmMode] = React.useState<TInputMode>('normal');
  const [confirmMessage, setConfirmMessage] = React.useState<string>('');
  const [showCountDown, setShowCountDown] = React.useState<boolean>(true);
  const captchaRef = React.useRef<ReCAPTCHA>(null)


  React.useEffect(() => {
    const sp = new URLSearchParams(params.search);
    const token = sp.get('token');
    if (token) {
      const { channel, code } = JSON.parse(atob(token));
      store.auth.setChannel(channel);
      setConfirmCode(code);
      setTimeout(async () => {
        await onSubmit({channel, code});
        // setShowCountDown(true);
      }, 100);
    }
  }, [params]);

  const { t } = useTranslation();
  React.useEffect(() => {
    TabsCommutator.on(tabsCommutationKey, () => {
      store.auth.tryRedirectToReferredApp({
        url: store.auth.referrer,
        fail: error => {
          console.log('* from email confirmation redirect err -', error)
        }
      })
    });
    return () => TabsCommutator.off(tabsCommutationKey);
  }, []);

  React.useEffect(() => {
    setConfirmMode('normal');
    setConfirmMessage('');
  }, [confirmCode]);

  const onSubmit = async (params: { evt?: any; channel?: string; code?: string;}) => {
    params.evt && params.evt.preventDefault();
    store.setLoading(true);
    setShowCountDown(false);
    try {
      const token = await captchaRef.current?.executeAsync()
      if (token) {
        const result = await ChronosAuthApi.confirm({ channel: params.channel || store.auth.channel, code: params.code || confirmCode, captchaToken: token }) as any;
        store.auth.setJid(result.jid);
        TabsCommutator.emit(tabsCommutationKey, true);
      } else {
        console.error('Captcha error');
      }

      store.auth.tryRedirectToReferredApp({
        url: store.auth.referrer,
        success: () => {
          // const partner = store.auth.partner
          // if (partner && this.$metrika) {
          //   this.$metrika.userParams({
          //     partner: Boolean(partner),
          //     ['partner_id']: partner
          //   })
          //   this.$metrika.params({
          //     ['from_partner']: partner
          //   })
          // }
        },
        fail: error => {
          console.log('* from email confirmation redirect err -', error)
        }
      })
    } catch (error: any) {
      setShowCountDown(false)
      setConfirmMode('error');
      setConfirmMessage(t(error.message));
    } finally {
      store.setLoading(false);
    }
  }

  const requestCodeAgain = async () => {
    store.setLoading(true);
    setShowCountDown(false);
    try {
      await ChronosAuthApi.resendRegisterCode(store.auth.registerId);
      setShowCountDown(true);
      show({ text: t("chronos.auth.confirmationEmail.requestCodeAgain.true"), type: 'success', timeout: 5000 });
    } catch (error) {
      show({ text: t("chronos.auth.confirmationEmail.requestCodeAgain.error"), type: 'error', timeout: 5000 });
    } finally {
      setShowCountDown(true);
      store.setLoading(false);
    }
  }

  return (
    <PageContainer backButton={<BackButton to='/signup' caption={t("chronos.auth.confirmationEmail.changeData")}/>}>
      <Form className="form signin" onSubmit={(evt) => onSubmit({evt})}>
        <div className="form-header">
          <div className="form-title">{t("chronos.auth.confirmationEmail.confirmMail")}</div>
          <div className="form-subtitle">{t("chronos.auth.confirmationEmail.sentTo")} <a href={`mailto:${store.auth.channel}`}>{store.auth.channel}</a> {t("chronos.auth.confirmationEmail.code_enterEmail")}</div>
        </div>
        <div className="form-content">
          <InputContainer
            label={t("chronos.auth.confirmationEmail.enterCode")}
            message={
              (showCountDown
                ? <CountDown minutes={1} startMessage={t("chronos.auth.confirmationEmail.codeNotComming")} endMessage={t("chronos.auth.confirmationEmail.requestNewCode")} onClick={requestCodeAgain}/>
                : t(confirmMessage)
              ) as any // FIXME: any
            }>
            <Input
              name="confirm-code"
              size='big'
              value={confirmCode}
              mode={confirmMode}
              placeholder="• • • •"
              onChange={(val: string) => setConfirmCode(val)}
            />
          </InputContainer>

          <FormControls className='form-buttons'>
            <Button name="submit-button" size='big' type="submit" disabled={!confirmCode}>{t("base.confirm")}</Button>
          </FormControls>
        </div>
      </Form>

      <ReCAPTCHA
          sitekey="6LdqFGUnAAAAAFPjZAmWlI1FS7TQNxF6vIsf5JKR"
          size="invisible"
          ref={captchaRef}
      />
    </PageContainer>
  );
});

// function useTranslation(): { t: any; } {
//   throw new Error('Function not implemented.');
// }
